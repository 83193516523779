import React from 'react'
import { useColorMode } from 'theme-ui'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import Layout from '@components/Layout'
import {
  NarrowSection,
  SectionTopHeader,
  SectionWrapper,
  Section,
  SectionGridLayout2Column,
} from '@components/Section'
import Seo from '@components/SEO'

import {
  SectionParagraph,
  SectionHeading,
  SectionHeader,
} from '@components/Section/SectionHeader'
import Video from '../../components/Video'

export const query = graphql`
  query {
    profileImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
        relativeDirectory: { eq: "team" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 250)
          }
          extension
          publicURL
        }
      }
    }
  }
`

interface ImageNode {
  name: string
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData
  }
}

interface Props {
  data: {
    profileImages: {
      edges: {
        node: ImageNode
      }[]
    }
  }
}

const ThankYouCallScheduled = ({ data }: Props) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const profileImageMap: Record<string, ImageNode> = {}
  data.profileImages.edges.forEach((edge) => {
    profileImageMap[edge.node.name] = edge.node
  })
  const team = [
    {
      name: 'Derek Liang',
      title: 'Owner',
      profileImageData:
        profileImageMap['derek-liang'].childImageSharp.gatsbyImageData,
      alt: 'This is Derek Liang, a team member of Auotiv.',
    },
  ]
  const teamProfiles = team.map((member) => {
    return (
      <MemberSection>
        <GatsbyImage
          alt={member.alt}
          image={member.profileImageData}
        ></GatsbyImage>
        <MemberDetails isDark={isDark}>
          <div>{member.name}</div>
          <p>{member.title}</p>
        </MemberDetails>
      </MemberSection>
    )
  })

  return (
    <Layout>
      <Seo
        image="/images/og/thank-you.png"
        url="/"
        title="Thank You - Call Scheduled"
        description="Thank you for scheduling a call with us!"
      />
      <SectionTopHeader>
        <SectionWrapper>
          <SectionHeader>Call Scheduled</SectionHeader>
          <SectionHeading.h1>Thank You</SectionHeading.h1>
          <SectionParagraph>
            I have sent you an email and text confirmation for the call. Please
            make sure you add it to your calendar!
          </SectionParagraph>
        </SectionWrapper>
      </SectionTopHeader>

      <NarrowSection medium style={{ marginTop: '100px', textAlign: 'center' }}>
        <SectionHeader>Next Steps</SectionHeader>
        <SectionHeading.h3>
          Watch This Brief Introduction Video
        </SectionHeading.h3>

        {/* For YouTube, use embedded url. Example: https://www.youtube.com/embed/j5SHMJ6mUoA */}
        <Video
          videoSrcURL="https://fast.wistia.net/embed/iframe/r5hcmyon6k?videoFoam=true"
          videoTitle="Thank You for Scheduling Video"
          videoHost="wistia"
        />
      </NarrowSection>

      <NarrowSection medium style={{ marginTop: '50px' }}>
        <SectionHeading.h3 style={{ textAlign: 'center' }}>
          Key Points
        </SectionHeading.h3>
        <SectionHeading.h4>
          1. All Decisions Makers On the Call
        </SectionHeading.h4>
        <SectionParagraph>
          We ask that all decisions makers to be on the call. If there is anyone
          missing, we'll simply reschedule the call since we don't want to play
          a game of telephone.
        </SectionParagraph>

        <SectionHeading.h4>2. Environment</SectionHeading.h4>
        <SectionParagraph>
          Please make sure that you are in a quiet room and do not take the call
          from your car, airport or any other noisy place.
        </SectionParagraph>

        <SectionParagraph>
          Be ready to view screen share. We'll be sharing our screen with you to
          show how our systems will get you the results we promise.
        </SectionParagraph>

        <SectionHeading.h4>3. Be Ready to Take Action</SectionHeading.h4>
        <SectionParagraph>
          If the opportunity is a good fit, we would love to onboard you as a
          client to get you results as soon as possible.
        </SectionParagraph>
      </NarrowSection>

      <NarrowSection medium style={{ marginTop: '50px' }}>
        <SectionHeading.h3 style={{ textAlign: 'center' }}>
          Appointment Details
        </SectionHeading.h3>

        <SectionGridLayout2Column>
          <div>{teamProfiles}</div>

          <div>
            <SectionParagraph>
              Thanks for scheduling your discovery call!
            </SectionParagraph>
            <SectionParagraph>
              I look forward to helping you grow your assisted living community
              and help you deliver exceptional care to your residents!
            </SectionParagraph>
            <SectionParagraph>
              If you have any questions or want to reach out to me personally
              you can email me at{' '}
              <EmailLink href={'mailto:sales@auotiv.com'}>
                sales@auotiv.com
              </EmailLink>
              .
            </SectionParagraph>
          </div>
        </SectionGridLayout2Column>
      </NarrowSection>

      {/* Insert Our Mission */}
      {/* Insert Testimonies */}
    </Layout>
  )
}

const MemberDetails = styled.div`
  margin-top: 20px;
  width: 100%;
  color: ${(p) => (p.isDark ? '#fff' : '#000')};

  div {
    font-family: ${(p) => p.theme.fonts.serif};
    font-weight: 400;
    font-size: 18px;
  }

  p {
    font-family: ${(p) => p.theme.fonts.serif};
    font-weight: 200;
    font-size: 14px;
  }
`

const MemberSection = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  min-height: 240px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    border-radius: 50%;
  }
`

const EmailLink = styled.a`
  color: ${(p) => p.theme.colors.flashyAccent};
`

export default ThankYouCallScheduled
