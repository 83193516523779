import styled from '@emotion/styled'
import React, { useEffect } from 'react'

interface VideoProps {
  videoSrcURL: string
  videoTitle: string
  videoHost?: 'wistia'
}

const Video: React.FC<VideoProps> = ({
  videoSrcURL,
  videoTitle,
  videoHost,
}) => {
  let iframeClass

  switch (videoHost) {
    case 'wistia': {
      iframeClass = 'wistia_embed'
    }
  }

  useEffect(() => {
    if (videoHost === 'wistia') {
      const script1 = document.createElement("script");
      const script2 = document.createElement("script");
  
      script1.src = "https://fast.wistia.com/embed/medias/videolink.jsonp";
      script1.async = true;
  
      script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
      script2.async = true;
  
      document.body.appendChild(script1);
      document.body.appendChild(script2);
    }
  })

  return (
    <VideoContainer className="video">
      <iframe
        className={iframeClass}
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder={0}
      />
      {/* <div
        className="wistia_responsive_padding"
      >
        <div
        >
          <iframe
            src="https://fast.wistia.net/embed/iframe/r5hcmyon6k?videoFoam=true"
            title="Auotiv - Thank You for Scheduling Video"
            allow="autoplay; fullscreen"
            allowTransparency={true}
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            allowFullScreen={true}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div> */}
      <script
        src="https://fast.wistia.net/assets/external/E-v1.js"
        async
      ></script>
    </VideoContainer>
  )
}

export default Video

const VideoContainer = styled.div`
  iframe {
    min-width: 100%;
  }
`
